import { useQuery } from "vue-query";
import { reactive } from '@vue/reactivity'
// import httpClient from "@/http/httpClient";
// import store from "@/store";
import { httpReq } from "@/htttpReq";


const fetchData = async (pricelistId) => {

  const response = await httpReq.get(`ordering-portal/account-information-maintenance/price-lists/${pricelistId}/customers`);
  return response.data.data;
};

export default function useGetCustomersByPricelist(pricelistId) {

  const queryKey = ["price-list-customers-for", { pricelistId: pricelistId }];

  return useQuery(
    queryKey,
    () => fetchData(pricelistId),
    {
      refetchOnWindowFocus: false,
      //   placeholderData: [],
        staleTime: Infinity,
      onError: (error) => {
        // store.commit("errorsModal/openModal", error.response.data.errors);
      },
      enabled: !!pricelistId
    },
    
  );
}
