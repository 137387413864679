import { useMutation, useQueryClient } from "vue-query";
// import httpClient from "@/http/httpClient";
import { httpReq } from "@/htttpReq";
import store from '@/store/store'
// import { useToast } from "vue-toastification";
// const toast = useToast();
import { ref, toRefs, computed, reactive } from "@vue/reactivity";

const action = async (payload) => {
  const { priceListId } = payload;
  await httpReq.delete(`ordering-portal/account-information-maintenance/price-lists/${priceListId}`);
};

export default function useDeletePriceList() {
  const cache = useQueryClient();
  const mutation = useMutation(({ payload }) => action(payload), {
    onError: (error) => {
      store.commit("errorsModal/openModal", error.response.data.errors);
    },
    onSuccess: (data, variables, context) => {
      cache.invalidateQueries(["price-lists"]);
      // toast.success("Account update.");
    },
    onSettled: (data, error, variables, context) => {},
  });

  return reactive({
    deletePriceList: mutation.mutateAsync,
    deletePriceListStatus: mutation,
  });
}
