import { ref, toRefs, computed, reactive } from "@vue/reactivity";
import { useRoute, useRouter } from "vue-router";

// initialSelected = {
//     fieldName: 'name',
//     direction: 'asc'
// }
export default function useSortByQueryHelper(fields = [], initialSelected) {
  const route = useRoute();
  const router = useRouter();

  const availableSortOptions = computed(() => {
    return fields.map((i) => {
      return {
        fieldName: i.fieldName,
        direction: "",
        displayName: i.displayName
      };
    });
  });

  const selectedSortOption = computed(() => {
    const { sort, ...restOfTheQuery } = route.query;
    if (!sort && !initialSelected) return {fieldName: null, direction: null}//availableSortOptions.value[0];
    if (!sort && initialSelected) return initialSelected;

    const { [0]: fieldName, [1]: direction } = sort.toString().split("|");
    return {
      fieldName,
      direction,
      displayName: availableSortOptions.value.find(x => x.fieldName === fieldName).displayName
    };
  });

  const changeSortOption = (fieldName) => {
    const { sort, ...restOfTheQuery } = route.query;
    let direction = "asc";

    if (sort) {
      const { [0]: field, [1]: directionQ } = sort.toString().split("|");
      if (fieldName === field) {
        direction = directionQ === "asc" ? "desc" : "asc";
      }
    }
    router.replace({
      query: { ...restOfTheQuery, ...{ sort: `${fieldName}|${direction}` } },
    });
  };

  return {
    selectedSortOption,
    changeSortOption,
    availableSortOptions,
  };
}
