import { useQuery } from "vue-query";
import { httpReq } from "@/htttpReq";


const fetchData = async (pricelistId) => {

  const response = await httpReq.get(`ordering-portal/account-information-maintenance/price-lists/${pricelistId}`);
  return response.data.data;
};

export default function useGetPriceListProductMargins(pricelistId) {

  const queryKey = ["price-list-product-margins", { pricelistId: pricelistId }];

  return useQuery(
    queryKey,
    () => fetchData(pricelistId),
    {
      refetchOnWindowFocus: false,
      //   placeholderData: [],
        staleTime: Infinity,
      onError: () => {
        // store.commit("errorsModal/openModal", error.response.data.errors);
      },
      enabled: !!pricelistId
    },

  );
}
