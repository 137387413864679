<script setup>

import { useRouter, useRoute } from "vue-router";
import { ref } from "@vue/reactivity";
import { watch } from "@vue/runtime-core";
import { debounce } from 'lodash'
import useSortByQueryHelper from "../../../functions/useSortByQueryHelper";

const router = useRouter()
const route = useRoute()

const { availableSortOptions, changeSortOption, selectedSortOption } = useSortByQueryHelper([{fieldName: 'name', displayName: 'Name'}, {fieldName: 'margin', displayName: 'Margin'}, {fieldName: 'end_date', displayName: 'Expiry Date'}])

const searchText = ref(route.query.search);

const updateSearchQuery = debounce((val) => {
  const { search, ...restOftheQuery } = route.query;

  let resultedQuery = restOftheQuery;
  let searchQuery = val ? { search: val } : {};
  resultedQuery = { ...resultedQuery, ...searchQuery };

  router.replace({
    query: { ...resultedQuery },
  });
}, 300);

watch(
  searchText,
  (newVal, oldVal) => {
    if (newVal.length >= 0) updateSearchQuery(newVal);
  },
  { immediate: false }
);

</script>

<template>
  <div>
    <div
      class="
        tw-gap-4
        tw-flex
        tw-justify-between
        tw-items-center
      "
    >
        <!-- <h4 class="tw-hidden- sm:tw-block page-title">Price lists</h4> -->

        <div class="tw-flex tw-items-center tw-gap-4 tw-ml-auto">
            <router-link :to="{name: 'price-lists.create'}">
              <button class="tw-btn-primary-success">
                <svg-icon name="solid/plus"/>
              </button>
            </router-link>
            <button @click="changeSortOption(sortOption.fieldName)" v-for="sortOption in availableSortOptions" class="btn btn_link tw-btn-link tw-py-1.5" :class="{'tw-btn-link-active': selectedSortOption.fieldName === sortOption.fieldName}">
                <span class="tw-capitalize">{{ sortOption.displayName }}</span>
                <template v-if="selectedSortOption.fieldName === sortOption.fieldName">
                    <svg-icon v-if="selectedSortOption.direction === 'asc'" name="solid/arrow-up" class="tw-text-xs tw-h-3 tw-w-3"/>
                    <svg-icon v-else name="solid/arrow-down" class="tw-text-xs tw-h-3 tw-w-3"/>
                </template>
            </button>
            <div>
                <input
                    v-model="searchText"
                    type="search"
                    class="form__input tw-input-search"
                    name="query"
                    placeholder="Start typing to search"
                />
            </div>
        </div>
    </div>
  </div>
</template>
