<template>
  <div class="tw-card tw-overflow-visible tw-text-black-gray tw-h-full tw-flex tw-flex-col">
    <div class="tw-card-head tw-flex tw-justify-between tw-gap-2 tw-items-center" :class="{'tw-bg-gray-200 tw-text-black-gray': priceList.is_expired || !isActive}">
      <div class="tw-text-ellipsis tw-overflow-hidden tw-break-all tw-uppercase tw-flex tw-items-center tw-gap-2 tw-w-full">
        <div class="tw-flex tw-items-center tw-justify-between tw-w-full">
          <div>
            <span>{{priceList.name}} {{priceList.is_expired ? '(EXPIRED)': !isActive? '(INACTIVE)': null}}</span>

            <span  class="tw-text-xs tw-ml-2">
              <svg-icon v-if="priceList.is_default" name="solid/thumbtack"></svg-icon>
            </span>
          </div>
<!--          <span v-if="!isCustomersLoading && !isLoading"> {{ data.is_for_all_customers? 'All': customers.length }}</span>-->
        </div>
      </div>
      <div>
        <CardAction :triggerColorClass="!isActive? 'tw-text-black-gray hover:tw-text-opacity-50': undefined">
          <template #action-panel>
               <div class="tw-flex tw-flex-col tw-gap-1">
                  <button v-if="!priceList.is_default" @click="showActivateConfirmation = true" class="btn btn_link tw-h-0 tw-btn-link tw-m-0 tw-uppercase tw-flex tw-gap-2 tw-items-center tw-font-bold">
                    <div class="tw-w-4">
                      <svg-icon class="tw-w-4" v-if="isActive" name="solid/ban"/>
                      <svg-icon v-else class="tw-w-4" name="solid/check"/>

                    </div>
                    <span>{{ isActive? 'MAKE INACTIVE': 'MAKE ACTIVE'}} </span>
                  </button>
                  <button @click="showCloneConfirmation = true" class="btn btn_link tw-h-0 tw-btn-link tw-m-0 tw-uppercase tw-flex tw-gap-2 tw-items-center tw-font-bold">
                    <div class="tw-w-4">
                      <svg-icon class="tw-w-4" name="solid/clone"/>
                    </div>
                    <span>CLONE</span>
                  </button>
                  <button @click="showDeleteConfirmation = true" :disabled="priceList.is_default" class="btn btn_link tw-h-0 tw-btn-link tw-m-0 tw-uppercase tw-flex tw-gap-2 tw-items-center tw-font-bold">
                    <div class="tw-w-4">
                      <svg-icon class="tw-w-4" name="solid/xmark-to-slot"/>
                    </div>
                    <span>REMOVE</span>
                  </button>
               </div>
          </template>
        </CardAction>
      </div>
    </div>

    <div class="tw-card-body tw-relative tw-flex-1 tw-flex tw-flex-col">
      <div class="tw-flex tw-justify-between tw-mb-4">
        <p class="tw-capitalize">Type: {{ priceList.type }}</p>
        <p class="tw-capitalize">Margin: <strong>{{ priceList.latest_values.margin }}%</strong></p>
      </div>
      <div class="tw-flex tw-justify-between tw-mb-4">
        <p><svg-icon name="solid/users"></svg-icon>&nbsp;
          <span v-if="priceList.is_for_all_customers">All</span>
          <span v-else>{{priceList.attached_customers_count}}</span>
        </p>
      
        <p v-if="!priceList.is_expired && priceList.latest_values.end_date && priceList.latest_values.end_date < '2200-01-01'">
          Expires on {{$filters.formatDate(priceList.latest_values.end_date) }}
        </p>
      </div>

      <div class="tw-space-y-2 tw-mb-4">
        <div class="tw-flex tw-justify-between tw-gap-4" v-for="item in itemsToShow">
          <div class="tw-overflow-hidden tw-overflow-ellipsis tw-whitespace-nowrap">
            {{ item.product_type.name }} - {{ item.product_style.name }} - {{ item.product_grade.name }}
          </div>
          <span>
            {{ Number(item.margin).toFixed(2) }}%
          </span>
        </div>

        <button v-if="hasMore" @click="showMore = !showMore" class="tw-flex tw-items-center btn btn_link tw-btn-link tw-p-0 tw-m-0 tw-uppercase tw-text-xs tw-font-bold tw-underline">
          <svg-icon v-if="!showMore" name="solid/plus" class="tw-text-xs tw-w-2 tw-h-2"></svg-icon>
          <svg-icon v-else name="solid/minus" class="tw-text-xs tw-w-2 tw-h-2"></svg-icon>

          <span>{{ showMore? 'Show less': 'Show more' }}</span>
        </button>
      </div>

      <button class="tw-btn-card-main tw-mt-auto" @click="$router.push({name: 'price-lists.edit', params:{pricelistId: priceList.id}})">EDIT</button>
    </div>
  </div>


  <Modal v-if="showDeleteConfirmation" :handleClose="() => showDeleteConfirmation = false" :busy="deletePriceListStatus.isLoading">
    <template #title-text>
      Confirmation
    </template>

    <template #description>
      <div>
        <div>Are you sure you want to remove?</div>

        <div class="tw-flex tw-gap-4 tw-mt-4">
          <button type="button" class="tw-btn-primary-success" @click="remove">
            YES
          </button>
          <button type="button" class="tw-btn-secondary-success" @click="showDeleteConfirmation = false">
            No
          </button>
        </div>
      </div>
    </template>

    <template #footer>
      {{null}}
    </template>
  </Modal>

  <Modal v-if="showCloneConfirmation" :handleClose="() => showCloneConfirmation = false" :busy="clonePriceListStatus.isLoading">
    <template #title-text>
      Confirmation
    </template>

    <template #description>
      <div>
        <div>Are you sure you want to clone?</div>

        <div class="tw-flex tw-gap-4 tw-mt-4">
          <button type="button" class="tw-btn-primary-success" @click="clone">
            YES
          </button>
          <button type="button" class="tw-btn-secondary-success" @click="showCloneConfirmation = false">
            No
          </button>
        </div>
      </div>
    </template>

    <template #footer>
      {{null}}
    </template>
  </Modal>

  <Modal v-if="showActivateConfirmation" :handleClose="() => showActivateConfirmation = false" :busy="markActiveStatus.isLoading">
    <template #title-text>
      Confirmation
    </template>

    <template #description>
      <div>
        <div>Are you sure you want to {{ isActive? 'inactivate': 'activate' }}?</div>

        <div class="tw-flex tw-gap-4 tw-mt-4">
          <button type="button" class="tw-btn-primary-success" @click="handleActivation">
            YES
          </button>
          <button type="button" class="tw-btn-secondary-success" @click="showActivateConfirmation = false">
            No
          </button>
        </div>
      </div>
    </template>

    <template #footer>
      {{null}}
    </template>
  </Modal>
</template>

<script setup>
import { ref, computed } from '@vue/reactivity';
import useGetPriceListProductMargins from '../../../hooks/price-lists/useGetPriceListProductMargins';
import useMarkPriceListActive from '../../../hooks/price-lists/useMarkPriceListActive';
import useDeletePriceList from '../../../hooks/price-lists/useDeletePriceList';
import useClonePriceList from '../../../hooks/price-lists/useClonePriceList';
import CardAction from '../../../components/cards/CardAction.vue';
import { ContentLoader } from "vue-content-loader";
import Modal from '../../../components/modals/Modal.vue';
import useGetCustomersByPricelist from '../../../hooks/price-lists/useGetCustomersByPricelist';

const props = defineProps({
  priceList: {
    type: Object,
    required: true
  }
})

const { data, isLoading } = {data: props.priceList, isLoading: false};
const { markActive, markActiveStatus } = useMarkPriceListActive();
const { deletePriceList, deletePriceListStatus } = useDeletePriceList();
const { clonePriceList, clonePriceListStatus } = useClonePriceList();
const { data: customers, isLoading: isCustomersLoading } = useGetCustomersByPricelist(props.priceList.id)

const showMore = ref(false);
const hasMore = computed(() => (props.priceList.latest_values.product_margins || []).slice(2).length > 0)
const itemsToShow = computed(() => showMore.value? props.priceList.latest_values?.product_margins: props.priceList.latest_values.product_margins.slice(0,2))
const isActive = computed(() => props.priceList.state !== 'inactive')

const showActivateConfirmation = ref(false)
const showDeleteConfirmation = ref(false)
const showCloneConfirmation = ref(false)

const handleActivation = async() => {
  await markActive({
    payload: {
      priceListId: props.priceList.id
    }
  }).finally(() => {
    showActivateConfirmation.value = false
  })
}


const clone = async () => {
  await clonePriceList({
    payload: {
      priceListId: props.priceList.id
    }
  }).finally(() => {
    showCloneConfirmation.value = false
  })
}

const remove = async () => {
  await deletePriceList({
    payload: {
      priceListId: props.priceList.id
    }
  }).finally(() => {
    showDeleteConfirmation.value = false
  })
}

</script>

<style lang="scss" scoped>
  .tw-card {
    font-size: 15px;
  }
</style>

